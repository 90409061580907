/**
 * Based on https://ui.shadcn.com/docs/components/select
 */

import * as SelectPrimitive from '@radix-ui/react-select';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';
import * as React from 'react';

import { Button } from '@/components/button';
import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Icon } from '@/components/icon';
import { SelectProps } from '@/components/ui/inputs/select';
import { usePortalContainerRef } from '@/lib/context/portal-container/use-portal-container-ref';
import { tv } from '@/utils/styles';

const Select = SelectPrimitive.Root;

const SelectGroup = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Group>
>(({ className, children, ...props }, ref) => {
  const { group } = selectStyles();
  return (
    <SelectPrimitive.Group ref={ref} className={group({ className })} {...props}>
      {children}
    </SelectPrimitive.Group>
  );
});

SelectGroup.displayName = SelectPrimitive.Group.displayName;

const SelectValue = SelectPrimitive.Value;

interface SelectTriggerProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  showShadow?: boolean;
  variant?: SelectProps['variant'];
  error?: boolean;
  isLocked?: boolean;
  isLockedFunc?: () => void;
  size?: SelectProps['size'];
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectTriggerProps>(
  (
    { className, children, showShadow, variant, error, isLocked, isLockedFunc, size, isActive, isDisabled, ...props },
    ref,
  ) => {
    const { trigger } = selectStyles({ fieldError: error, showShadow, size, isActive });

    return (
      <ConditionalWrapper
        condition={!isLocked}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(buttonChildren) => <SelectPrimitive.Trigger asChild>{buttonChildren}</SelectPrimitive.Trigger>}
      >
        <Button
          ref={ref}
          onClick={!isLocked ? props.onClick : () => isLockedFunc?.()}
          iconRight={
            !isLocked ? (
              <ChevronDown className="w-4 shrink-0 opacity-80" />
            ) : (
              <Icon name="lock" size="base" color="states.locked" />
            )
          }
          variant={variant === 'dark' ? 'darkInput' : 'lightInput'}
          className={trigger()}
          isDisabled={isDisabled}
          truncateText
          {...props}
        >
          <div className="truncate text-left">{children}</div>
        </Button>
      </ConditionalWrapper>
    );
  },
);

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => {
  const { scrollButton } = selectStyles();
  return (
    <SelectPrimitive.ScrollUpButton ref={ref} className={scrollButton({ className })} {...props}>
      <ChevronUp className="h-4 w-4" />
    </SelectPrimitive.ScrollUpButton>
  );
});

SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => {
  const { scrollButton } = selectStyles();
  return (
    <SelectPrimitive.ScrollDownButton ref={ref} className={scrollButton({ className })} {...props}>
      <ChevronDown className="h-4 w-4" />
    </SelectPrimitive.ScrollDownButton>
  );
});

SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => {
  const { content, viewport } = selectStyles({ position: position === 'popper' ? 'popper' : undefined });

  // If the Select is rendered within a portal, mount it inside
  const portalContainer = usePortalContainerRef();

  return (
    <SelectPrimitive.Portal container={portalContainer?.current}>
      <SelectPrimitive.Content ref={ref} className={content({ className })} position={position} {...props}>
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport className={viewport()}>{children}</SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});

SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => {
  const { label } = selectStyles();
  return <SelectPrimitive.Label ref={ref} className={label({ className })} {...props} />;
});

SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  const { item } = selectStyles();
  return (
    <SelectPrimitive.Item ref={ref} className={item({ className })} {...props}>
      <span className="absolute right-2 h-3.5 w-3.5">
        <SelectPrimitive.ItemIndicator className="flex items-center justify-center">
          <Check className="h-4 w-4 text-lightBlue-700" />
        </SelectPrimitive.ItemIndicator>
      </span>

      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});

SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => {
  const { separator } = selectStyles();
  return <SelectPrimitive.Separator ref={ref} className={separator({ className })} {...props} />;
});

SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export const selectStyles = tv({
  slots: {
    trigger: null,
    content:
      'relative z-19 max-h-[500px] max-h-[calc(var(--radix-popper-available-height)-theme(spacing.4))] min-w-32 max-w-80 overflow-hidden rounded-md border border-grey-300 bg-white shadow-md !duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-[.99] data-[state=open]:zoom-in-[.99] data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
    viewport: 'p-2',
    item: 'relative flex w-full cursor-default select-none items-center rounded px-2 py-1.5 pr-7 text-baseSm outline-none focus:bg-blue-50 data-[disabled]:pointer-events-none data-[highlighted]:bg-grey-100 data-[state=checked]:!bg-blue-50 data-[disabled]:opacity-50',
    separator: '-mx-1 my-1 h-px bg-grey-200',
    group: 'mt-2 border-t border-dashed border-grey-300 pt-2 first:mt-0 first:border-t-0 first:pt-0',
    label: 'py-1 pl-2 pr-2 text-sm font-semibold uppercase text-grey-900',
    scrollButton: 'flex cursor-default items-center justify-center py-1',
    error: 'mb-2 text-forms-states-error',
  },
  variants: {
    variant: {
      light: {},
    },
    position: {
      popper: {
        content:
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        viewport: 'w-full min-w-[calc(var(--radix-select-trigger-width)-2px)]',
      },
    },
    fieldError: {
      true: {
        trigger:
          'border-forms-states-error text-forms-states-error !shadow-[0_0_0_1px_theme(colors.forms.states.error)]',
      },
    },
    size: {
      small: {
        trigger: 'h-[var(--input-height-small)] sm:!px-3',
      },
      medium: {},
    },
    showShadow: {
      true: {
        trigger: 'shadow-softer',
      },
    },
    isActive: {
      true: {
        trigger: '!border-lightBlue-600',
      },
    },
  },
});

export { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectSeparator, SelectTrigger, SelectValue };
