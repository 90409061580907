import * as PopoverPrimitive from '@radix-ui/react-popover';
import React from 'react';

import { usePortalContainerRef } from '@/lib/context/portal-container/use-portal-container-ref';
import { tv } from '@/utils/styles';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    footer?: React.ReactNode;
    wrapperClassName?: string;
  }
>(({ className, wrapperClassName, align = 'start', sideOffset = 4, children, footer, ...props }, ref) => {
  // If the popover is rendered within a portal, mount it inside
  const portalContainer = usePortalContainerRef();

  const { content, contentWrapper } = styles();
  return (
    <PopoverPrimitive.Portal container={portalContainer?.current}>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        collisionPadding={5}
        className={contentWrapper({ className: wrapperClassName })}
        {...props}
      >
        <div className={content({ className })}>{children}</div>
        {Boolean(footer) && footer}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
});
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const styles = tv({
  slots: {
    contentWrapper:
      'z-19 rounded-md border border-grey-300 bg-white shadow-softer outline-none !duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-[.99] data-[state=open]:zoom-in-[.99] data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 md:w-auto',
    content: 'rounded-t p-4',
  },
});

export { Popover, PopoverContent, PopoverTrigger };
