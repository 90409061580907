import { flatMap, get, isEmpty } from 'lodash';
import { useSetRecoilState } from 'recoil';

import { Icon } from '@/components/icon';
import { Error } from '@/components/inputs/error';
import { Label } from '@/components/inputs/label';
import { Tooltip } from '@/components/tooltip';
import {
  Select as SelectRoot,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  selectStyles,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/inputs/select/shared-components';
import { lockedDialogIsOpenState } from '@/state/user';
import { OptionGroup, OptionItem } from '@/types';
import { FieldErrors } from '@/types/form-types';
import { trackUser } from '@/utils/tracking';

export interface SelectProps extends FieldErrors {
  id: string;
  label?: string;
  placeholder?: string;
  options: OptionGroup[];
  selected?: string;
  defaultSelectedItem?: string;
  disabled?: boolean;
  minWidth?: string;
  maxWidth?: string;
  buttonWidth?: string;
  dropdownWidth?: string;
  onSelect?: (value: OptionItem) => void;
  onBlur?: () => void;
  isActive?: boolean;
  trackingCategory?: string;
  variant?: 'light' | 'dark';
  size?: 'small' | 'medium';
  showShadow?: boolean;
  isLocked?: boolean;
  hideErrorMessage?: boolean;
  tooltipContent?: React.ReactNode | string;
  errorMessage?: string;
  className?: string;
}

const Select = ({
  id,
  label,
  placeholder = 'Choose an option',
  minWidth,
  maxWidth,
  buttonWidth,
  dropdownWidth,
  options,
  selected,
  defaultSelectedItem,
  disabled,
  onSelect,
  onBlur,
  isActive = false,
  variant = 'light',
  showShadow = true,
  trackingCategory = 'Select Box',
  isLocked,
  errors,
  hideErrorMessage,
  hideLabel,
  size = 'medium',
  tooltipContent,
  isOpen,
  side,
  position = 'item-aligned',
  avoidCollisions = true,
  className,
  ...props
}: SelectProps) => {
  const fieldError = get(errors, id);
  const errorMessage = fieldError?.message;

  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const flatOptions = flatMap(options, 'items');

  // Ensure the selected value is one of the options
  const validSelectedValue = flatOptions.find((option) => option.value === selected)?.value;

  const handleValueChange = (value: string) => {
    const selectedOption = flatOptions.find((option) => option.value === value);

    if (selectedOption && onSelect) {
      onSelect(selectedOption);
    }
  };

  const handleLockedDialogOpen = () => {
    setLockedDialogIsOpen(true);
    // Analytics Event
    trackUser.event(`Click Locked: ${trackingCategory}`, { id });
  };

  const { error } = selectStyles({ fieldError: !!fieldError });

  return (
    <div id={id} className={className} {...props}>
      <div className="flex">
        <Label isHidden={!label} htmlFor={id}>
          {label || 'Select an option'}
        </Label>
        {tooltipContent && (
          <div className="ml-auto cursor-pointer">
            <Tooltip content={tooltipContent} variant="dark" maxWidth="260px">
              <span>
                <Icon name="question-mark-circled" size={3} />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <SelectRoot
        defaultValue={defaultSelectedItem}
        value={validSelectedValue}
        onValueChange={handleValueChange}
        disabled={disabled}
        open={isOpen}
      >
        <SelectTrigger
          variant={variant}
          showShadow={showShadow}
          error={!!fieldError}
          isLocked={isLocked}
          isLockedFunc={handleLockedDialogOpen}
          size={size}
          isActive={isActive}
          isDisabled={disabled}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>

        <SelectContent avoidCollisions={avoidCollisions} side={side} position={position}>
          {
            options.reduce(
              (result, group, groupIndex) => {
                if (isEmpty(group.items)) {
                  return result;
                }
                result.groups.push(
                  // eslint-disable-next-line react/no-array-index-key
                  <SelectGroup key={groupIndex}>
                    {Boolean(group.title) && <SelectLabel>{group.title}</SelectLabel>}
                    {group.items.map((item) => {
                      return (
                        <SelectItem key={item.value} value={item.value} disabled={item.disabled}>
                          {item.label}
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>,
                );

                return result;
              },
              { groups: [] as React.ReactNode[] },
            ).groups
          }
        </SelectContent>
      </SelectRoot>
      {Boolean(errorMessage && !hideErrorMessage) && <Error className={error()}>{errorMessage}</Error>}
    </div>
  );
};

export { Select };
